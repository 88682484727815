// rootReducer.ts
import { combineReducers } from '@reduxjs/toolkit'
import authReducer from '@redux/auth/authSlice'
import chatBotReducer from '@redux/chatBot/chatBotSlice'
import sessionsThread from '@redux/sessions/sessionsSlice'
import publicFilingsReducer from '@redux/publicFilings/publicFilingsSlice'
import researchReportsReducer from '@redux/researchReports/researchReportsSlice'

const appReducer = combineReducers({
  auth: authReducer,
  publicFilings: publicFilingsReducer,
  researchReports: researchReportsReducer,
  chatBot: chatBotReducer,
  sessions: sessionsThread
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET_STORE') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
