import SectionContainer from '@coreComponents/SectionContainer'
import Chizl from '@icons/Chizl'
import ChizlText from '@icons/ChizlText'
import Institution1 from './assets/1.png'
import Institution2 from './assets/2.png'
import Institution4 from './assets/4.png'
import Institution5 from './assets/5.png'
import Institution6 from './assets/6.png'
import Macbook from './assets/macbook.png'
import Product1 from './assets/products-1.png'
import Product2 from './assets/products-2.png'
import Product3 from './assets/products-3.png'
import Lines from './assets/lines.png'
import SearchDocumentIcon from '@icons/SearchDocumentIcon'
import GlobalPublicIcon from '@icons/GlobalPublicIcon'
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2'
import { TfiBarChart } from 'react-icons/tfi'
import { TbMath } from 'react-icons/tb'
import { IoClose, IoDocumentsOutline, IoMenu } from 'react-icons/io5'
import ChizlGradient from '@icons/ChizlGradient'
import ChizlTextGradient from '@icons/ChizlTextGradient'
import { BsLinkedin } from 'react-icons/bs'
import React, { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa6'
import { IoSend } from 'react-icons/io5'
import ChizlIcon from '@coreComponents/ChizlIcon'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ClickAwayListener from '@coreComponents/ClickAwayListener'
import { smoothScrollTo } from '@utils/smoothScrollTo'
import { Drawer, IconButton, List, ListItem, ListItemText, ListSubheader } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { Link } from 'react-router-dom'

function CMD({ words = [] }: { words: string[] }) {
  let wordsArray = words

  const typingSpeed = 55 //ms
  const waitingSpeed = 1200 //ms
  const deletingSpeed = 35 //ms

  const [typing, setTyping] = useState('')
  const [action, setAction] = useState('type')
  const [wordIndex, setWordIndex] = useState(0)

  //helper function to show the word
  const typeCharacters = (currentIndex: number, wordLength: number, wordToType: string) => {
    setTyping(currentWord => currentWord + wordToType[currentIndex])
    setTimeout(() => {
      if (wordLength - currentIndex - 1 !== 0) {
        typeCharacters(currentIndex + 1, wordLength, wordToType)
      } else {
        setAction('delete')
      }
    }, typingSpeed)
  }

  //helper function to delete the word
  const deleteCharacters = (wordLength: number) => {
    setTyping(currentWord => currentWord.slice(0, -1))
    setTimeout(() => {
      if (wordLength > 0) {
        deleteCharacters(wordLength - 1)
      }
    }, deletingSpeed)
  }

  //function that will fully type a word
  const showWord = (word: string) => {
    let currentIndex = 0
    let wordLength = word.length

    typeCharacters(currentIndex, wordLength, word)
  }

  useEffect(() => {
    if (action === 'type') {
      setTimeout(() => {
        showWord(wordsArray[wordIndex])
      }, waitingSpeed)
    } else if (action === 'delete') {
      setTimeout(() => {
        deleteCharacters(wordsArray[wordIndex].length - 1)
        setWordIndex(i => {
          if (i < wordsArray.length - 1) return i + 1
          else return 0
        })
      }, waitingSpeed)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action])

  useEffect(() => {
    if (typing.length === 0) {
      setAction('type')
    }
  }, [typing])

  return (
    <div className='bg-gray-gradient rounded-full w-full max-w-[550px] h-[60px] flex-center p-[1.5px] mb-[50px] z-10'>
      <div className='bg-white rounded-full w-full h-full flex items-center px-[20px]'>
        <FaPlus className='text-gray-500' size={13} />
        <div className='bg-gray-400 w-[2px] h-[10px] rounded-full mx-[10px]' />
        <span className='text-lg text-truncate'>{typing}</span>
        <style>
          {`
          @keyframes blink {
            0%, 100% { opacity: 1; }
            50% { opacity: 0; }
          }

          .blink {
            animation: blink 1s step-start infinite;
          }
        `}
        </style>
        <div className='h-[20px] w-[2px] bg-gray-900 rounded-full blink' />

        {/* <IoSend className='ml-auto text-gray-gradient' size={18} /> */}
        <svg width='25' height='25' viewBox='0 0 25 25' className='ml-auto mb-[-6.5px] mr-[-4px]'>
          <defs>
            <linearGradient id='gradient' x1='0%' y1='0%' x2='100%' y2='100%'>
              <stop offset='0%' stopColor='#101011' />
              <stop offset='100%' stopColor='#A1A1A1' />
            </linearGradient>
          </defs>
          <IoSend style={{ fill: 'url(#gradient)' }} size={18} />
        </svg>
      </div>
    </div>
  )
}

// ** Components
const GradientBox = ({ innerClassName = '', ...rest }) => (
  <div className={`p-[1.8px] bg-gray-gradient rounded-[12px] w-fit ${rest.className}`}>
    <div className={`p-[6px] bg-white rounded-[10px] w-full h-full ${innerClassName}`}>{rest.children}</div>
  </div>
)

const Header: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open)
  }

  const smoothScrollTo = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      setDrawerOpen(false)
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <header className={`w-full top-0 fixed z-50 transition-all duration-300 ${drawerOpen ? 'h-[100vh]' : 'h-[80px]'}`}>
      <style>{`
        header {
          background: rgba(255, 255, 255, 0.46);
          box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10.4px);
          -webkit-backdrop-filter: blur(12.4px);
          border-bottom: 1px solid rgba(255, 255, 255, 0.39);
        }
      `}</style>
      <SectionContainer className='flex items-center justify-between'>
        <div className='flex items-center gap-[10px]'>
          <Chizl className='hidden md:block' />
          <ChizlText />
        </div>

        <ul className={`hidden md:flex gap-[40px] mr-[55px] ${drawerOpen ? 'flex-col mt-[20px]' : ''}`}>
          <li className='relative group'>
            <button
              onClick={() => {
                smoothScrollTo('products')
              }}
            >
              Products
            </button>
            <div className='absolute bg-black h-[1.5px] w-0 group-hover:w-full transition-all' />
          </li>
          <li className='relative group'>
            <button
              onClick={() => {
                smoothScrollTo('contact_us')
              }}
            >
              Contact Us
            </button>
            <div className='absolute bg-black h-[1.5px] w-0 group-hover:w-full transition-all' />
          </li>
        </ul>

        <div className={`hidden md:flex gap-[40px] font-[600] ${drawerOpen ? 'flex-col mt-[20px]' : ''}`}>
          <div className='p-[1.8px] bg-gray-gradient rounded-[12px]'>
            <Link to='/login'>
              <button className='px-[28px] py-[7px] bg-white rounded-[10px]'>
                <span className='text-gray-gradient font-[500]'>Log In</span>
              </button>
            </Link>
          </div>
        </div>

        {/* Hamburger icon for mobile */}
        <div className='md:hidden'>
          <button onClick={toggleDrawer(!drawerOpen)} color='inherit'>
            {/* <IoMenu size={20} /> */}
            <div className='relative w-[24px] h-[24px] flex flex-col items-center justify-center gap-[7px]'>
              <div
                className={`w-full h-[2px] bg-black transition-all duration-300 ${drawerOpen ? 'rotate-45 translate-y-[4px]' : ''}`}
              />
              <div
                className={`w-full h-[2px] bg-black transition-all duration-300 ${drawerOpen ? '-rotate-45 translate-y-[-4.5px]' : ''}`}
              />
            </div>
          </button>
        </div>
      </SectionContainer>

      {/* Full-screen mobile menu */}
      {drawerOpen && (
        <AnimatePresence>
          <div className='h-dvh relative'>
            <div className='flex flex-col items-start m-[30px]'>
              {[
                {
                  title: 'Products',
                  onClick: () => {
                    smoothScrollTo('products')
                  }
                },
                {
                  title: 'Contact Us',
                  onClick: () => {
                    smoothScrollTo('contact_us')
                  }
                },
                {
                  title: 'Get Started',
                  onClick: () => {
                    smoothScrollTo('contact_us')
                  }
                },
                {
                  title: (
                    <a
                      className='flex items-center gap-[10px] text-3xl font-[600]'
                      target='_blank'
                      href='https://www.linkedin.com/company/chizl/'
                    >
                      Follow Us <BsLinkedin size={23} />
                    </a>
                  ),
                  onClick: () => {}
                }
              ].map((el, index) => (
                <motion.button
                  transition={{ delay: 0.045 * index }}
                  initial={{ translateY: '-20px', opacity: 0 }}
                  animate={{ translateY: '0px', opacity: 1 }}
                  exit={{ translateY: '-20px', opacity: 0 }}
                  onClick={el.onClick}
                  className='mb-4 text-3xl text-left font-semibold'
                >
                  {el.title}
                </motion.button>
              ))}
            </div>
          </div>
        </AnimatePresence>
      )}
    </header>
  )
}

const Hero = () => (
  <SectionContainer className='flex-center flex-col gap-[20px]'>
    <h2 className='text-5xl md:text-6xl font-semibold text-center text-gray-gradient'>Get More Done with Chizl</h2>
    <h2 className='font-semibold text-xl text-center mb-[25px] text-gray-950'>
      Trained by Finance Professionals for Finance Professionals
    </h2>
    <CMD
      words={[
        'لخص أساس الإعداد بإختصار',
        'ما مستحقات تكلفة عقود المشاريع في ٣٠ يونيو ٢٠٢٤ ؟',
        // 'ما نسبة عمولات مبيعات مستحقة الدفع من من إجمالي الدائنة التجارية والأخرى ؟',
        // 'What are the potential risks associated with the company’s current debt levels?',
        'Calculate the company’s debt-to-equity ratio',
        'What provisions does the balance sheet have?',
        'Whats the current ratio?',
        'Plot the company’s operating expenses on a pie chart?'
      ]}
    />
    <img alt='macbook' aria-label='macbook' src={Macbook} className='max-w-[700px] w-full z-10' />
    <img src={Lines} className='absolute -z-0 mx-auto w-[1600px] object-contain top-[100px]' />
    <p className='text-gray-500 text-2xl max-w-[1100px] text-center mt-[40px]'>
      Chizl optimizes the workflow of financial analysts by leveraging artificial intelligence and machine learning
      methods to automate redundant tasks
    </p>
  </SectionContainer>
)

const EnrolledInstitutions = () => (
  <SectionContainer className='my-[30px] flex flex-col items-center'>
    <h2 className='text-2xl text-gray-400 text-center my-[50px]'>Institutional Analysts Enrolled in Beta Program</h2>

    <div className='flex-center gap-[62px] flex-wrap w-[105%]'>
      <img src={Institution2} className='w-[170px] object-contain' />
      <img src={Institution1} className='w-[240px] object-contain' />
      <img src={Institution4} className='w-[160px] object-contain' />
      <img src={Institution5} className='w-[180px] object-contain' />
      <img src={Institution6} className='w-[180px] object-contain' />
    </div>
  </SectionContainer>
)

const Products = () => (
  <SectionContainer id='products'>
    <h2 className='text-xl text-center mb-[27px] bg-[#ECE7E4] w-fit mx-auto px-[14px] py-[5px] rounded-[9px]'>
      Products
    </h2>
    <h3 className='text-3xl md:text-4xl text-center font-[500] mb-[40px]'>
      Augment Your Workflows
      <br />
      Using Domain Specific Models
    </h3>

    <div className='flex items-stretch flex-row gap-[40px] justify-center max-w-[1100px] mx-auto flex-wrap'>
      <div className='min-w-[325px] max-w-[330px]'>
        {/* <GradientBox innerClassName='bg-[#D9D9D9]' className='mb-[15px]'> */}
        <img src={Product1} className='w-[320px] h-[200px] mb-[15px]' />
        {/* </GradientBox> */}
        <h3 className='font-[500] text-2xl'>Public Filings</h3>

        <p className='text-[#282828]'>
          Search and analyze company filings from the <strong>Gulf Cooperation Council (GCC)</strong> and the{' '}
          <strong>United States (US)</strong> to access accurate information tailored to your specific requirements.
          This streamlined process allows you to quickly{' '}
          <strong>retrieve the data you need for informed decision-making.</strong>
        </p>
      </div>
      <div className='min-w-[325px] max-w-[330px]'>
        {/* <GradientBox innerClassName='bg-[#D9D9D9]' className='mb-[15px]'> */}
        <img src={Product2} className='w-[320px] h-[200px] mb-[15px]' />
        {/* </GradientBox> */}
        <h3 className='font-[500] text-2xl'>Multi Document Reports</h3>

        <p className='text-[#282828]'>
          Upload any PDF document, <strong>both confidential or non-confidential.</strong> Our chatbot is designed to
          extract the necessary information to address your inquiries effectively. Once the response is generated, the
          platform automatically navigates to the pertinent section of the document, emphasizing it for quick reference
          and providing you with instant context.
        </p>
      </div>
      <div className='min-w-[325px] max-w-[330px]'>
        {/* <GradientBox innerClassName='bg-[#D9D9D9]' className='mb-[15px]'> */}
        <div className='bg-[#EAEAEA] rounded-[10px] '>
          <img src={Product3} className='w-[320px] h-[200px] mb-[15px] p-[7px]' />
        </div>
        {/* </GradientBox> */}
        <h3 className='font-[500] text-2xl'>Chizl General</h3>

        <p className='text-[#282828]'>
          Chat Search and analyze company filings from the Gulf Cooperation Council (GCC) and the United States (US) to
          access accurate information tailored to your specific requirements. This streamlined process allows you to
          quickly retrieve the data you need for informed decision-making.
        </p>
      </div>
    </div>
  </SectionContainer>
)

const Goals = () => (
  <SectionContainer outerContainerClassName='relative'>
    <img
      src={Lines}
      className='absolute z-0 mx-auto w-[1600px] object-contain top-[-150px] left-0 right-0 rotate-180'
      style={{ transform: 'rotateY(180deg)' }}
    />
    <h2 className='text-3xl md:text-4xl text-gray-gradient text-center max-w-[800px] mx-auto z-10'>
      Our goal is to save{' '}
      <strong className='text-secondary-blue text-3xl md:text-4xl font-[500] text-center'>at least 25%</strong> of an
      analyst’s time by automating data extraction and processing
    </h2>
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-[100px] z-10'>
      {[
        {
          icon: <SearchDocumentIcon color='white' width={25} height={25} />,
          title: (
            <>
              Search <br /> Documents
            </>
          ),
          desc: 'Find and extract relevant information from your documents​'
        },
        {
          icon: <HiOutlineClipboardDocumentList size={28} strokeWidth={1.25} color='white' />,
          title: (
            <>
              Summarize <br /> Documents
            </>
          ),
          desc: 'Summarize lengthy documents into shorter versions​'
        },
        {
          icon: <TfiBarChart size={22} style={{ marginTop: '-4px' }} color='white' />,
          title: (
            <>
              Visualize <br /> Data
            </>
          ),
          desc: 'Generate, export, and format charts based on your prompts​'
        },
        {
          icon: <GlobalPublicIcon width={25} height={25} color='white' />,
          title: (
            <>
              Access <br /> Public Filings
            </>
          ),
          desc: 'Pull public company filings from nine different exchanges​'
        },
        {
          icon: <IoDocumentsOutline size={24} strokeWidth={1.25} color='white' />,
          title: (
            <>
              Compare <br /> Reports​
            </>
          ),
          desc: 'Upload multiple documents into the drive and ask to compare those documents​'
        },
        {
          icon: <TbMath size={24} strokeWidth={1.5} color='white' />,
          title: (
            <>
              Compute <br /> Numbers
            </>
          ),
          desc: 'Retrieve answers from mathematical equations quickly​​'
        }
      ].map(item => (
        <div className='flex flex-[0.1] items-start gap-[25px] max-w-[300px]'>
          <div className='flex-center bg-gray-gradient w-[40px] h-[40px] rounded-full'>{item.icon}</div>

          <div className='flex-[0.9]'>
            <h3 className='text-gray-gradient text-2xl font-semibold'>{item.title}</h3>
            <p className='text-gray-gradient'>{item.desc}</p>
          </div>
        </div>
      ))}
    </div>
    '
  </SectionContainer>
)

const Beta = () => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <ContactUsModal open={isModalOpen} setOpen={setIsModalOpen} />
      <SectionContainer outerContainerClassName='relative' id='contact_us'>
        <Chizl className='blur-[6px] text-gray-300 absolute left-[-100px] top-[-95px] ' width={280} height={200} />

        <h3 className='text-gray-gradient text-4xl font-[500] text-center'>
          Interested in becoming a <span className='text-4xl italic font-[600] text-accent-orange'>BETA</span> user?
        </h3>

        <div className='mx-auto my-[90px] border-[2px] border-[#EBEAED] rounded-[7px] p-[25px] w-fit flex flex-col items-center justify-between gap-[30px] max-w-[400px]'>
          <h4 className='text-3xl text-[#49494A] text-center'>Demo + Beta User Program</h4>
          <p className='text-[#1514396d] text-center'>
            Schedule a meeting with our team to get a demo of our current beta
          </p>
          <button className='text-white text-lg bg-accent-orange px-[17px] py-[9px] rounded-full' onClick={openModal}>
            Contact Us
          </button>
        </div>
      </SectionContainer>
    </>
  )
}

const Footer = () => (
  <SectionContainer>
    <footer>
      <div className='flex items-start justify-between flex-wrap gap-[30px]'>
        {/* Left */}
        <div>
          <div className='flex items-center gap-[10px]'>
            <ChizlGradient width={74} height={74} />
            <ChizlTextGradient width={200} height={100} />
          </div>
          <p className='font-semibold text-gray-500 text-2xl'>Get More Done with Chizl</p>
          <p className='text-gray-500 text-xl'>A one-stop-shop for all analysts’ needs</p>
        </div>

        {/* Right */}
        <div className='flex items-start gap-[80px]'>
          <div>
            <h4 className='text-xl font-[500] mb-[33px]'>Product</h4>
            <ul className='text-xl font-[300] gap-[11px] flex flex-col'>
              <li className='text-lg' onClick={() => smoothScrollTo('products')}>
                Use Cases
              </li>
              <li className='text-lg' onClick={() => smoothScrollTo('contact_us')}>
                Pricing
              </li>
              <li className='text-lg' onClick={() => smoothScrollTo('contact_us')}>
                Demo
              </li>
            </ul>
          </div>
          <div>
            <h4 className='text-xl font-[500] mb-[33px]'>About Us</h4>
            <ul className='text-xl font-[300] gap-[11px] flex flex-col'>
              <li className='text-lg'>Team</li>
              <li>
                <a
                  className='text-lg flex items-center gap-[10px]'
                  target='_blank'
                  href='https://www.linkedin.com/company/chizl/'
                >
                  LinkedIn <BsLinkedin size={18} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Divider */}
      <div className='h-[1.5px] w-full my-[44px] bg-gray-400' />

      <div className='flex items-center justify-between flex-wrap gap-[30px]'>
        {/* Left */}
        <div>
          <p className='text-lg'>©2024 Chizl Technology Solutions FZ-LLC</p>
        </div>

        {/* Right */}
        <div className='flex items-center gap-[50px]'>
          <p className='text-lg'>Privacy Policy</p>
        </div>
      </div>
    </footer>
  </SectionContainer>
)

const ContactUsModal: React.FC<{
  open: boolean
  onRequestClose?: () => void
  setOpen: (val: any) => void
}> = ({ open, onRequestClose, setOpen }) => {
  interface FormData {
    fullName: string
    lastName: string
    email: string
    phone: string
    website: string
    title: string
    message?: string // Optional message field
  }

  const schema = yup.object().shape({
    fullName: yup.string().required('Full name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Invalid email format').required('Email is required'),
    phone: yup.string().required('Phone number is required'),
    website: yup.string().required('Website is required'),
    title: yup.string().required('Title is required'),
    message: yup.string().max(500, 'Message cannot exceed 500 characters') // Max length for message
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  const onSubmit = (data: FormData) => {
    console.log('Form submitted:', data)
    onRequestClose && onRequestClose()
  }

  if (!open) return null

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false)
          reset()
        }}
      >
        <div className='bg-white rounded-lg p-6 w-[90vw] max-w-[600px] max-h-[700px] overflow-y-auto'>
          <div className='flex items-center justify-between'>
            <h2 className='text-lg font-semibold mb-4'>Contact Us</h2>
            <button
              onClick={() => {
                setOpen(false)
                reset()
              }}
            >
              <IoClose />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-4'>
              <label className='block text-sm font-medium'>Full Name *</label>
              <Controller
                name='fullName'
                control={control}
                render={({ field }) => (
                  <input {...field} className='mt-1 block w-full border border-gray-300 rounded-md p-2' />
                )}
              />
              {errors.fullName && <p className='text-red-500 text-xs'>{errors.fullName.message}</p>}
            </div>

            <div className='mb-4'>
              <label className='block text-sm font-medium'>Email *</label>
              <Controller
                name='email'
                control={control}
                render={({ field }) => (
                  <input type='email' {...field} className='mt-1 block w-full border border-gray-300 rounded-md p-2' />
                )}
              />
              {errors.email && <p className='text-red-500 text-xs'>{errors.email.message}</p>}
            </div>

            <div className='mb-4'>
              <label className='block text-sm font-medium'>Phone</label>
              <Controller
                name='phone'
                control={control}
                render={({ field }) => (
                  <input type='tel' {...field} className='mt-1 block w-full border border-gray-300 rounded-md p-2' />
                )}
              />
              {errors.phone && <p className='text-red-500 text-xs'>{errors.phone.message}</p>}
            </div>

            <div className='mb-4'>
              <label className='block text-sm font-medium'>Website *</label>
              <Controller
                name='website'
                control={control}
                render={({ field }) => (
                  <input type='url' {...field} className='mt-1 block w-full border border-gray-300 rounded-md p-2' />
                )}
              />
              {errors.website && <p className='text-red-500 text-xs'>{errors.website.message}</p>}
            </div>

            <div className='mb-4'>
              <label className='block text-sm font-medium'>Title *</label>
              <Controller
                name='title'
                control={control}
                render={({ field }) => (
                  <input {...field} className='mt-1 block w-full border border-gray-300 rounded-md p-2' />
                )}
              />
              {errors.title && <p className='text-red-500 text-xs'>{errors.title.message}</p>}
            </div>

            {/* New Message Field */}
            <div className='mb-4'>
              <label className='block text-sm font-medium'>Message</label>
              <Controller
                name='message'
                control={control}
                render={({ field }) => (
                  <>
                    <textarea
                      {...field}
                      className='mt-1 block w-full border border-gray-300 rounded-md p-2'
                      maxLength={300}
                      rows={4}
                    />
                    <p className='text-gray-600 text-xs text-right'>{field.value?.length ?? 0}/300</p>
                  </>
                )}
              />

              {errors.message && <p className='text-red-500 text-xs'>{errors.message.message}</p>}
            </div>

            <div className='flex-center'>
              <button type='submit' className='w-full max-w-[150px] mx-auto bg-black text-white rounded-md p-2'>
                Submit Form
              </button>
            </div>
          </form>
        </div>
      </ClickAwayListener>
    </div>
  )
}

function LandingV2() {
  return (
    <div className='bg-white text-black flex flex-col gap-[65px] pt-[130px] '>
      <Header />
      <Hero />
      <EnrolledInstitutions />
      <Products />
      <Goals />
      <Beta />
      <Footer />
    </div>
  )
}

export default LandingV2
