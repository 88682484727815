import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@redux/store'
import ResearchReportFile from '@components/research-reports-components/ResearchReportFile'
import {
  addChatBotSelectedDocument,
  clearFiling,
  clearSelectedDocuments,
  removeChatBotSelectedDocument,
  setChatBotSelectedDocuments,
  setDocumentIds,
  setIsPrivate,
  setLanguage,
  setOffering
} from '@redux/chatBot/chatBotSlice'
import { ResearchReportsDocumentInterface } from '@api/research_reports'
import { ShimmerDiv } from 'shimmer-effects-react'
import { addSelectedDocuments, removeSelectedDocumentById } from '@redux/researchReports/researchReportsSlice'
import { dashboardConfig } from 'pages/DashboadLayout/dashboardConfig'

function ResearchReportsBody() {
  // Hooks
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  // States
  const loading = useAppSelector(state => state.researchReports.fetchLoading)
  const searchValue = useAppSelector(state => state.researchReports.searchValue) || ''
  const researchReports = useAppSelector(state => state.researchReports.researchReports)
  // const chatBotSelectedDocuments = useAppSelector(state => state.chatBot.selectedDocuments)
  const { selectedDocuments } = useAppSelector(state => state.researchReports)
  const selectedPrivacyFilter = useAppSelector(state => state.researchReports.selectedDocumentPrivacyFilter)

  // Handlers
  const handleFileSingleClick = (document: ResearchReportsDocumentInterface) => {
    if (selectedDocuments.find(doc => doc.id === document.id)) {
      dispatch(removeSelectedDocumentById(document.id ?? ''))
      // dispatch(setDocumentIds([document.id ?? '']))
    } else {
      dispatch(addSelectedDocuments(document))
    }
    dispatch(setIsPrivate(document.is_private ?? false))
    dispatch(setLanguage('En'))
    dispatch(clearFiling())
    dispatch(setOffering(2))
  }

  const handleFileDoubleClick = (documentId: string) => {
    navigate(`/dashboard/multi_reports/${documentId}`)
  }

  // Filter research reports by search value
  const filteredReports = researchReports?.filter(document => {
    const matchesSearchValue = document.document_name.toLowerCase().includes(searchValue.toLowerCase())
    const matchesPrivacyFilter =
      selectedPrivacyFilter === 'All' ||
      (selectedPrivacyFilter === 'Private' && document.is_private) ||
      (selectedPrivacyFilter === 'Public' && !document.is_private)

    return matchesSearchValue && matchesPrivacyFilter
  })

  return (
    <div
      className={`flex justify-start items-start gap-[30px] flex-wrap h-[100%] overflow-y-auto ${dashboardConfig.paddingClassName}`}
    >
      {loading ? (
        <div className='flex items-start justify-start gap-[30px] flex-wrap'>
          {new Array(6).fill(0).map(() => (
            <ShimmerDiv mode='dark' border={0} height={197} width={225} rounded={0.75} />
          ))}
        </div>
      ) : (
        filteredReports?.map((document, index) => (
          <ResearchReportFile
            id={document.id}
            key={document.id}
            name={document.document_name}
            imgUrl={document.screenshot_s3_path}
            extension={document.extension}
            isPrivate={document.is_private}
            isSelected={selectedDocuments?.find(el => el.id === document.id) ? true : false}
            onClick={() => handleFileSingleClick(document)}
            // onDoubleClick={() => document.id && handleFileDoubleClick(document.id)}
            dateUploaded={document.uploaded_at}
          />
        ))
      )}
    </div>
  )
}

export default ResearchReportsBody
