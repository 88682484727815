// ** Redux Imports
import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import rootReducer from './rootReducer'

export const store = configureStore({
  reducer: rootReducer
})

// ** Store Exports
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// ** Redux Hooks Exports
/**
 * Used instead of useDispatch and useSelector
 * for type checking and autocompletion
 */
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
